import React from "react";
import classNames from "classnames";

import { Type } from "@repo/nerdwallet-components";

import styles from "./PaymentBreakdown.module.less";

import { calculatePayments, formatMoney } from "../helpers";

interface ResultItemProps {
  title: React.ReactNode;
  children: React.ReactNode;
  separator?: boolean;
}

const ResultItem: React.FC<ResultItemProps> = ({
  title,
  separator = false,
  children,
}) => (
  <div
    className={classNames(styles.item, {
      [styles.separator]: separator,
    })}
  >
    <Type component="span" className={styles.itemHeader}>
      {title}
    </Type>
    <div className={styles.itemText}>{children}</div>
  </div>
);

interface PaymentBreakdownProps {
  constrained?: boolean;
  loanAmount?: number | string | null;
  result?: {
    monthlyPayment?: number;
    loanDurationInMonths?: number;
  };
}

const PaymentBreakdown: React.FC<PaymentBreakdownProps> = ({
  loanAmount = 0,
  result: { monthlyPayment, loanDurationInMonths } = {
    monthlyPayment: 0,
    loanDurationInMonths: 0,
  },
}) => {
  const { totalInterestPaid, totalLoanCost } = calculatePayments({
    loanAmount,
    monthlyPayment,
    loanDurationInMonths,
  });
  return (
    <div className={styles.container}>
      <div className={styles.mainResult}>
        <Type bold size="3">
          Over the course of the loan, expect to pay
        </Type>
        <Type color="green" size="4" className={styles.mainResultValue}>
          {monthlyPayment ? `${formatMoney(monthlyPayment)}/mo` : ""}
        </Type>
      </div>
      <Type bold className={styles.breakdownText}>
        Payment breakdown
      </Type>
      <ResultItem title="Total principal">{formatMoney(loanAmount)}</ResultItem>
      <ResultItem title="Total interest" separator>
        {formatMoney(totalInterestPaid)}
      </ResultItem>
      <ResultItem title="Total principal & interest">
        {formatMoney(totalLoanCost)}
      </ResultItem>
    </div>
  );
};

export default PaymentBreakdown;
