// ref: https://github.com/fundera/fundera-gatsby/blob/43eba22/packages/business-loan-calculator/src/BusinessLoanCalculator/helpers.js
export function validate(formData: any) {
  const errors = {} as any;
  const { loanAmount, additionalPayment, interestRate, time, timeOption } =
    formData;
  if (loanAmount < 99 || loanAmount > 2000000) {
    errors.loanAmount = "Loan amount must be between 99 - 2,000,000";
  }
  if (!loanAmount) {
    errors.loanAmount = "Please enter a loan amount";
  }
  if (additionalPayment && Number(additionalPayment) >= Number(loanAmount)) {
    errors.additionalPayment = "Must be less than the loan amount";
  }
  if (!interestRate) {
    errors.interestRate = "Please enter an interest rate";
  }

  if (!time) {
    errors.time = "Please enter a Loan term";
  }
  if (time < 1 || time > 120) {
    errors.time = "Maximum loan term: 120 months";
  }
  if (
    time < 1 ||
    (timeOption === "months" && time > 600) ||
    (timeOption === "years" && time > 50)
  ) {
    errors.time = "Invalid loan term";
  }
  return errors;
}

export function calculate(formData: any) {
  const { time, timeOption } = formData;
  const interestRate = Number(formData.interestRate) / 100;
  const loanAmount = Number(formData.loanAmount);
  const additionalPayment = Number(formData.additionalPayment);

  const monthlyInterestRate = interestRate / 12;
  const loanDurationInMonths = timeOption === "months" ? time : time * 12;

  const totalLoanCost =
    (monthlyInterestRate * loanAmount * loanDurationInMonths) /
    (1 - 1 / (1 + monthlyInterestRate) ** loanDurationInMonths);
  const totalInterestPaid = totalLoanCost - loanAmount;
  const monthlyPayment = totalLoanCost / loanDurationInMonths;

  if (!additionalPayment) {
    return {
      loanDurationInMonths,
      monthlyPayment,
      totalLoanCost,
      totalInterestPaid,
      interestRate,
      monthlyInterestRate,
    };
  }

  const monthlyPaymentAdditional =
    totalLoanCost / loanDurationInMonths + additionalPayment;
  const loanDurationInMonthsAdditional =
    Math.log(
      1 +
        monthlyInterestRate /
          (monthlyPaymentAdditional / loanAmount - monthlyInterestRate)
    ) / Math.log(1 + monthlyInterestRate);
  const totalLoanCostAdditional =
    (monthlyInterestRate * loanAmount * loanDurationInMonthsAdditional) /
    (1 - 1 / (1 + monthlyInterestRate) ** loanDurationInMonthsAdditional);
  const totalInterestPaidAdditional = totalLoanCostAdditional - loanAmount;

  return {
    loanDurationInMonths: loanDurationInMonthsAdditional,
    monthlyPayment: monthlyPaymentAdditional,
    totalLoanCost: totalLoanCostAdditional,
    totalInterestPaid: totalInterestPaidAdditional,
  };
}

const datesForCurrentMonthPayment = (incrementInMonths: number) => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + incrementInMonths);

  const longMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const shortYear = currentDate.toLocaleString("en-US", {
    year: "2-digit",
  });
  const fullYear = currentDate.toLocaleString("en-US", {
    year: "numeric",
  });

  return {
    longMonth,
    shortYear,
    fullYear,
  };
};

export const calculateMonthlyAmortization = (
  loanAmount: any,
  { monthlyInterestRate, loanDurationInMonths, monthlyPayment }: any
) => {
  const schedule = [];
  let remainingBalance = loanAmount;

  for (let i = 0; i < loanDurationInMonths; i++) {
    const interest = remainingBalance * monthlyInterestRate;
    const principal = monthlyPayment - interest;
    remainingBalance -= principal;

    const { longMonth, shortYear, fullYear } = datesForCurrentMonthPayment(
      i + 1
    );
    schedule.push({
      paymentDate: `${longMonth} \u2018${shortYear}`,
      interest: Math.round(interest * 100) / 100,
      principal: Math.round(principal * 100) / 100,
      balance:
        remainingBalance < 0 ? 0 : Math.round(remainingBalance * 100) / 100,
      year: fullYear,
    });
  }

  return schedule;
};

export const transformToYearlyAmortization = (monthlySchedule: any) => {
  if (monthlySchedule.length === 0) {
    return [];
  }
  const [firstPayment] = monthlySchedule;
  firstPayment.paymentDate = firstPayment.year;
  return monthlySchedule.reduce(
    (yearlySchedule: any, currentMonthPayment: any) => {
      const currentYearPayment = yearlySchedule[yearlySchedule.length - 1];
      if (currentYearPayment.year !== currentMonthPayment.year) {
        currentMonthPayment.paymentDate = currentMonthPayment.year;
        yearlySchedule.push(currentMonthPayment);
        return yearlySchedule;
      }
      currentYearPayment.interest += currentMonthPayment.interest;
      currentYearPayment.principal += currentMonthPayment.principal;
      currentYearPayment.balance = currentMonthPayment.balance;
      yearlySchedule[yearlySchedule.length - 1] = currentYearPayment;
      return yearlySchedule;
    },
    [firstPayment]
  );
};

export const calculatePayments = ({
  loanAmount,
  monthlyPayment,
  loanDurationInMonths,
}: any) => {
  const totalLoanCost =
    (Math.round(monthlyPayment * 100) / 100) * Number(loanDurationInMonths);
  const totalInterestPaid = totalLoanCost - Number(loanAmount);

  return { totalLoanCost, totalInterestPaid };
};

export const addCommas = (num: string | number | null): string => {
  if (!num) {
    return "";
  }
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const removeNonNumeric = (num: string): string =>
  num?.toString().replace(/[^0-9]/g, "");

export const formatMoney = (value: number | string | null): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(typeof value !== "number" || !value || isNaN(value) ? 0 : value);
};
