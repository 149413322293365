import { useState } from "react";
import classNames from "classnames";

import { Button, Column, Columns, Type } from "@repo/nerdwallet-components";

import Tooltip from "../../Tooltip";
import styles from "./LoanTypeOptions.module.less";

const DEFAULT = "I’ll choose my own estimates";
const PRESET_LOAN_TYPE_OPTIONS = [
  {
    text: DEFAULT,
    values: {
      loanAmount: null,
      time: null,
      interestRate: 1,
    },
    tooltip: null,
  },
  {
    text: "SBA 7(a) Loan",
    values: {
      loanAmount: 300000,
      time: 120,
      interestRate: 12,
    },
    tooltip: {
      title: "Did you know?",
      content:
        "To qualify for an SBA loan, lenders typically like to see at least two years in business, strong annual revenue and a good credit score, which starts around 690.",
    },
  },
  {
    text: "Online Term Loan",
    values: {
      loanAmount: 75000,
      time: 12,
      interestRate: 40,
    },
    tooltip: {
      title: "Did you know?",
      content:
        "Online lenders offer term loans up to $1 million and can provide faster funding than banks that offer small-business loans.",
    },
  },
  {
    text: "Business Line of Credit",
    values: { loanAmount: 20000, time: 12, interestRate: 15 },
    tooltip: {
      title: "Did you know?",
      content:
        "A business line of credit provides access to funds up to your credit limit, and you pay interest only on the money you’ve drawn.",
    },
  },
];

interface LoanTypeOptionsProps {
  setLoanAmount: (_loanAmount: string | number | null) => void;
  setTime: (_time: string | number | null) => void;
  setInterestRate: (_interestRate: number) => void;
}

const LoanTypeOptions: React.FC<LoanTypeOptionsProps> = ({
  setLoanAmount,
  setTime,
  setInterestRate,
}) => {
  const [selected, setSelected] = useState(DEFAULT);

  const onClickLoanType = ({
    id,
    loanAmount,
    time,
    interestRate,
  }: {
    id: string;
    loanAmount: number | null;
    time: number | null;
    interestRate: number;
  }) => {
    setSelected(id);
    setLoanAmount(loanAmount);
    setTime(time);
    setInterestRate(interestRate);
  };

  return (
    <div className={styles.container}>
      <Columns>
        {PRESET_LOAN_TYPE_OPTIONS.map((option) => (
          <Column key={option.text} width={{ desktop: 6, mobile: 6 }}>
            <Button
              className={classNames(styles.button, {
                [styles.selected]: selected === option.text,
              })}
              onClick={() =>
                onClickLoanType({ id: option.text, ...option.values })
              }
              data-nw-mp
              data-calculator-calculate
              data-calculator-preset={`Calculator Selection: ${option.text}`}
            >
              <Type
                component="div"
                size={0}
                className={classNames(styles.text, {
                  [styles.selectedText]: selected === option.text,
                  [styles.paddingRight]: !!option.tooltip,
                })}
              >
                {option.text}{" "}
                {option.tooltip && (
                  <div className={styles.tooltipContainer}>
                    <Tooltip
                      className={styles.tooltip}
                      content={
                        <Type component="p" className={styles.tooltipText}>
                          <b>{option.tooltip.title}</b> {option.tooltip.content}
                        </Type>
                      }
                    />
                  </div>
                )}
              </Type>
            </Button>
          </Column>
        ))}
      </Columns>
    </div>
  );
};

export default LoanTypeOptions;
