import { useState, useEffect, type ReactNode, Children, useRef } from "react";
import classNames from "classnames";

import leftArrow from "./left-caret.svg";
import rightArrow from "./right-caret.svg";

import styles from "./Carousel.module.less";

interface ResponsiveSetting {
  breakpoint: number;
  settings: {
    arrows?: boolean;
    dots?: boolean;
  };
}

const SWIPE_THRESHOLD = 0.3; // 30% of slide width
const TRANSITION_DURATION = 400; // ms
const RESISTANCE_FACTOR = 0.5;

interface Props {
  arrows?: boolean;
  dots?: boolean;
  dotsClass?: string;
  responsive?: ResponsiveSetting[];
  children: ReactNode;
}

export const Carousel = ({
  arrows = true,
  dots = true,
  dotsClass,
  responsive = [],
  children,
}: Props) => {
  const [activeSettings, setActiveSettings] = useState<{
    arrows?: boolean;
    dots?: boolean;
  }>({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragDelta, setDragDelta] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const animationFrameId = useRef<number>();

  const slides = Children.toArray(children);

  // Track container width with ResizeObserver
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setContainerWidth(entries[0].contentRect.width);
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  // Responsive handling
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      const sortedResponsive = [...responsive].sort(
        (a, b) => b.breakpoint - a.breakpoint
      );
      for (const setting of sortedResponsive) {
        if (windowWidth <= setting.breakpoint) {
          setActiveSettings(setting.settings);
          return;
        }
      }
      setActiveSettings({});
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [responsive]);

  const goToPrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const goToSlide = (index: number) => {
    if (index < 0 || index >= slides.length) return;
    setCurrentIndex(index);
  };

  const handleDragStart = (clientX: number) => {
    setIsDragging(true);
    setDragStartX(clientX);
    setDragDelta(0);
  };

  const handleDragMove = (clientX: number) => {
    if (!isDragging || !containerWidth) return;

    const delta = clientX - dragStartX;
    let newDelta = delta;
    let maxDelta = containerWidth; // Default to full slide width

    // Apply resistance and limit drag only at boundaries
    if (currentIndex === 0 && delta > 0) {
      // Dragging right from first slide
      newDelta = delta * RESISTANCE_FACTOR;
      maxDelta = containerWidth * 0.4;
    } else if (currentIndex === slides.length - 1 && delta < 0) {
      // Dragging left from last slide
      newDelta = delta * RESISTANCE_FACTOR;
      maxDelta = containerWidth * 0.1;
    }

    // Only apply limits when at boundaries
    if (currentIndex === 0 || currentIndex === slides.length - 1) {
      newDelta = Math.max(-maxDelta, Math.min(maxDelta, newDelta));
    }

    setDragDelta(newDelta);
  };

  const handleDragEnd = () => {
    if (!isDragging || !containerWidth) return;

    // Cancel any pending animation frames
    cancelAnimationFrame(animationFrameId.current!);

    const threshold = containerWidth * SWIPE_THRESHOLD;
    let newIndex = currentIndex;

    if (Math.abs(dragDelta) > threshold) {
      newIndex = currentIndex + (dragDelta > 0 ? -1 : 1);
    }

    newIndex = Math.max(0, Math.min(slides.length - 1, newIndex));

    // Animate to the new position
    setDragDelta(0);
    setCurrentIndex(newIndex);
    setIsDragging(false);
  };

  // Unified pointer handling
  const handlePointerStart = (clientX: number) => handleDragStart(clientX);
  const handlePointerMove = (clientX: number) => {
    cancelAnimationFrame(animationFrameId.current!);
    animationFrameId.current = requestAnimationFrame(() =>
      handleDragMove(clientX)
    );
  };

  // Touch handlers
  const handleTouchStart = (e: React.TouchEvent) =>
    handlePointerStart(e.touches[0].clientX);
  const handleTouchMove = (e: React.TouchEvent) =>
    handlePointerMove(e.touches[0].clientX);

  // Mouse handlers
  const handleMouseDown = (e: React.MouseEvent) =>
    handlePointerStart(e.clientX);
  const handleMouseMove = (e: React.MouseEvent) => handlePointerMove(e.clientX);

  // Calculate transform in pixels
  const basePosition = -currentIndex * containerWidth;
  const transform = basePosition + dragDelta;

  const showArrows = activeSettings.arrows ?? arrows;
  const showDots = activeSettings.dots ?? dots;

  return (
    <div
      ref={containerRef}
      className={styles.slider}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleDragEnd}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={handleDragEnd}
    >
      {showArrows && (
        <>
          <button
            className={styles.leftArrow}
            onClick={goToPrevious}
            tabIndex={0}
          >
            <img src={leftArrow.src} alt="Left arrow" />
          </button>
          <button className={styles.rightArrow} onClick={goToNext} tabIndex={1}>
            <img src={rightArrow.src} alt="Right arrow" />
          </button>
        </>
      )}

      <div
        className={styles.slidesWrapper}
        style={{
          transform: `translateX(${transform}px)`,
          transition: `transform ${isDragging ? 0 : TRANSITION_DURATION}ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`,
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={styles.slide}
            aria-hidden={index !== currentIndex}
          >
            {slide}
          </div>
        ))}
      </div>

      {showDots && (
        <div className={classNames(dotsClass, styles.dotsContainer)}>
          {slides.map((_, index) => (
            <button
              key={index}
              type="button"
              className={classNames(styles.dot, {
                [styles.active]: index === currentIndex,
              })}
              onClick={() => goToSlide(index)}
              onMouseDown={(e) => e.preventDefault()}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};
