import React from "react";

import { Column, Columns, Type } from "@repo/nerdwallet-components";

import Tooltip from "../../Tooltip";
import calculatorImage from "../calculator.svg";

import styles from "./Header.module.less";

interface HeaderProps {
  title?: string;
  subTitle?: string;
}

const Header: React.FC<HeaderProps> = ({ title, subTitle }) => (
  <Columns>
    <Column width={{ desktop: 2, mobile: 12 }}>
      <div className={styles.calculator}>
        <img
          className={styles.calculatorImage}
          alt="Loan calculator icon"
          src={calculatorImage.src}
        />
      </div>
    </Column>
    <Column width={{ desktop: 10, mobile: 12 }}>
      {title && (
        <div className={styles.headerContainer}>
          <h2 className={styles.header}>{title}</h2>
          <Tooltip
            placement="top"
            content={
              <Type className={styles.tooltipText}>
                The pre-filled values are general estimates of possible terms
                you may see with this type of loan. Any loan offer’s final
                interest rate and terms will depend on your qualifications.
              </Type>
            }
            offsetArrow
          />
        </div>
      )}
      <p className={styles.text}>{subTitle}</p>
    </Column>
  </Columns>
);

export default Header;
