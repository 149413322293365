import React, { useState, useRef, useEffect } from "react";

import { Button, Tabs } from "@repo/nerdwallet-components";

import Field from "./Field";
import Input from "./Field/Input";
import PrefixInput from "./Field/PrefixInput";
import RangeInput from "./Field/RangeInput";

import AmortizationSchedule from "./AmortizationSchedule";
import Header from "./Header";
import Footer from "./Footer";
import LoanTypeOptions from "./LoanTypeOptions";
import PaymentBreakdown from "./PaymentBreakdown";

import { useBusinessLoanCalculator } from "./hooks";
import { addCommas, removeNonNumeric } from "./helpers";

import styles from "./BusinessLoanCalculator.module.less";
import classNames from "classnames";

interface BusinessLoanCalculatorProps {
  title?: string;
  subTitle?: string;
  constrained?: boolean;
  cta?: React.ReactNode;
}

const BusinessLoanCalculator: React.FC<BusinessLoanCalculatorProps> = ({
  title = "Business loan calculator",
  subTitle = "Estimate payments to understand the cost of a business loan",
  constrained = false,
  cta,
}) => {
  const [loanAmount, setLoanAmount] = useState<string | number | null>(null);
  const [formattedLoanAmount, setFormattedLoanAmount] = useState<string>("");
  const [time, setTime] = useState<string | number | null>(null);
  const [interestRate, setInterestRate] = useState(1);

  const resultRef = useRef<HTMLDivElement>(null);

  const { result, errors } = useBusinessLoanCalculator({
    loanAmount,
    time,
    interestRate,
    timeOption: "months",
    additionalPayment: 0,
  });

  useEffect(() => {
    setFormattedLoanAmount(addCommas(loanAmount));
  }, [loanAmount]);

  const calculate = () => {
    if (!result || !resultRef?.current || window.innerWidth > 768) return;
    resultRef.current.scrollIntoView();
  };

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <Header title={title} subTitle={subTitle} />
      </div>
      <div className={styles.container}>
        <form
          className={classNames(styles.form, styles.column, {
            [styles.constrainedColumn]: constrained,
          })}
        >
          <div className={styles.field}>
            <label aria-label="Loan type" className={styles.label}>
              Loan type
            </label>
            <LoanTypeOptions
              setLoanAmount={setLoanAmount}
              setTime={setTime}
              setInterestRate={setInterestRate}
            />
          </div>

          <div className={styles.field}>
            <Field label="Loan amount" error={errors.loanAmount}>
              <PrefixInput
                className={styles.numericInput}
                name="loanAmount"
                prefix="$"
                precision="1"
                step="1"
                placeholder="0"
                min="0"
                type="tel"
                formNoValidate="formnovalidate"
                value={formattedLoanAmount || " "}
                onChange={(e) => {
                  e.target.validity.valid &&
                    setLoanAmount(removeNonNumeric(e.target.value));
                }}
                data-nw-mp
                data-calculator-input="Loan Amount"
              />
            </Field>
          </div>

          <div className={styles.field}>
            <Field label="Loan term (months)" error={errors.time}>
              <Input
                className={styles.numericInput}
                type="number"
                precision="1"
                step="1"
                min="0"
                max="600"
                placeholder="0 months"
                value={time || " "}
                onChange={(e) =>
                  e.target.validity.valid && setTime(e.target.value)
                }
                data-nw-mp
                data-calculator-input="Loan Term"
              />
            </Field>
          </div>

          <div className={styles.field}>
            <Field
              label="Annual percentage rate (APR)"
              error={errors.interestRate}
            >
              <div className={styles.verticalMargin}>
                <RangeInput
                  min="1"
                  max="100"
                  value={interestRate}
                  aria-label={`${interestRate}%`}
                  onChange={(e: any) => setInterestRate(e.target.value)}
                  data-nw-mp
                  data-calculator-input="APR"
                />
              </div>
              <span aria-hidden="true">{interestRate}%</span>
            </Field>
          </div>

          <div className={styles.justifyEnd}>
            <div className={classNames(styles.field, styles.buttonContainer)}>
              <Button
                className={classNames(styles.button, "clickable")}
                onClick={calculate}
                data-nw-mp
                data-calculator-calculate
              >
                Calculate
              </Button>
            </div>
          </div>
        </form>
        <div
          className={classNames(styles.column, styles.results, {
            [styles.constrainedColumn]: constrained,
            [styles.constrainedResults]: constrained,
          })}
          ref={resultRef}
        >
          <Tabs
            aria-label={title}
            tabs={[
              {
                label: "Payment Breakdown",
                content: (
                  <PaymentBreakdown
                    loanAmount={loanAmount}
                    result={result}
                    constrained={constrained}
                  />
                ),
              },
              {
                label: "Amortization Schedule",
                content: (
                  <AmortizationSchedule
                    loanAmount={loanAmount}
                    loanConfigs={result}
                  />
                ),
              },
            ]}
          />
          <div className={styles.footerContainer}>
            <Footer cta={cta} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessLoanCalculator;
