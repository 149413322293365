import Input from "../Input";
import styles from "./PrefixInput.module.less";
import classNames from "classnames";

interface PrefixInputProps {
  prefix: string;
  value?: any;
  className?: string;
  name?: string;
  precision?: string;
  step?: string;
  placeholder?: string;
  min?: string;
  type?: string;
  formNoValidate?: string;
  onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PrefixInput: React.FC<PrefixInputProps> = ({
  prefix,
  className,
  ...props
}) => {
  return (
    <div className={styles.wrapper}>
      <Input
        {...props}
        className={classNames(styles.input, className)}
        aria-label={`${prefix} ${props.value}`}
      />
      <span aria-hidden="true" className={styles.prefix}>
        {prefix}
      </span>
    </div>
  );
};

export default PrefixInput;
