import React from "react";

import { Table } from "@repo/nerdwallet-components";

import styles from "./AmortizationSchedule.module.less";

import {
  calculateMonthlyAmortization,
  formatMoney,
  transformToYearlyAmortization,
} from "../helpers";

const COLUMNS = [
  { id: "paymentDate", heading: "Payment date", fixed: true },
  { id: "principal", heading: "Principal" },
  { id: "interest", heading: "Interest" },
  { id: "balance", heading: "Balance" },
];

const formatSchedule = (
  schedule: {
    paymentDate: string;
    interest: number;
    principal: number;
    balance: number;
  }[]
): {
  key: string;
  paymentDate: string;
  interest: string;
  principal: string;
  balance: string;
}[] =>
  schedule.map(({ paymentDate, interest, principal, balance }) => ({
    key: paymentDate,
    paymentDate,
    interest: formatMoney(interest),
    principal: formatMoney(principal),
    balance: formatMoney(balance),
  }));

interface AmortizationScheduleProps {
  loanAmount?: number | string | null;
  loanConfigs?: {
    monthlyPayment?: number;
    loanDurationInMonths?: string | number;
    monthlyInterestRate?: number;
  };
}

const AmortizationSchedule: React.FC<AmortizationScheduleProps> = ({
  loanAmount,
  loanConfigs = {
    monthlyPayment: 0,
    loanDurationInMonths: 0,
    monthlyInterestRate: 0,
  },
}) => {
  const firstMonth = {
    key: "Today",
    paymentDate: "Today",
    principal: "$0",
    interest: "$0",
    balance: formatMoney(loanAmount || 0),
  };

  const monthlySchedule = calculateMonthlyAmortization(loanAmount, loanConfigs);
  let schedule = monthlySchedule;
  if (
    loanConfigs?.loanDurationInMonths &&
    typeof loanConfigs.loanDurationInMonths === "number" &&
    loanConfigs.loanDurationInMonths > 36
  ) {
    schedule = transformToYearlyAmortization(monthlySchedule);
  }

  const data = [firstMonth, ...formatSchedule(schedule)];
  return (
    <div className={styles.tableContainer}>
      <Table
        className={styles.table}
        columns={COLUMNS}
        data={data}
        fixedHeader={true}
        striped={false}
      />
    </div>
  );
};

export default AmortizationSchedule;
