import { Button, Type } from "@repo/nerdwallet-components";
// import TrackedButton from "@src/components/TrackedButton";

// import { useApplyUrl } from "@src/hooks/useApplyUrl";

import styles from "./Footer.module.less";

const CTA_TYPE = "business-loan-calculator";

interface FooterProps {
  cta?: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ cta }) => {
  // TODO: Uncomment when useApplyUrl is available and use TrackedButton
  // const applyUrl = useApplyUrl();
  const applyUrl =
    "https://www.nerdwallet.com/redirect/d56b267e-073b-11ea-a8c5-179bb79b48c9";

  return (
    <section className={styles.section}>
      <Type size={2} bold color="green-darkest">
        Get personalized small-business loan rates to compare
      </Type>
      <div className={styles.buttonContainer}>
        {cta || (
          <Button
            className={styles.button}
            href={`${applyUrl}&blc=yes`}
            target="_blank"
            data-cta-type={CTA_TYPE}
            primary
          >
            Get started
          </Button>
        )}
        <Type color="neutral-darker" className={styles.byNerdWallet}>
          with Fundera by NerdWallet
        </Type>
      </div>
    </section>
  );
};

export default Footer;
